import { render, staticRenderFns } from "./SMSTable.vue?vue&type=template&id=4289453e&scoped=true"
import script from "./SMSTable.vue?vue&type=script&lang=js"
export * from "./SMSTable.vue?vue&type=script&lang=js"
import style0 from "./SMSTable.vue?vue&type=style&index=0&id=4289453e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4289453e",
  null
  
)

export default component.exports