<template>
  <div id="page-shop_items">
    <h5 class="m-4">SMS</h5>

    <b-card>
      <b-row>
        <b-col lg="6">
          <memlist-date-picker
            v-model="from_date"
            class="mb-8"
            placeholder="Startdatum"
          />
        </b-col>
        <b-col lg="6">
          <memlist-date-picker
            v-model="to_date"
            class="mb-8"
            placeholder="Slutdatum"
          />
        </b-col>
      </b-row>
    </b-card>

    <div class="scroll-to-container"></div>
    <b-card
      title="Editera SMS"
      class="mb-2 edit-sms-container"
      ref="edit-sms"
      id="edit-sms"
      hide-footer
      v-if="form"
    >
      <div>
        <b-form class="mt-8" v-on:submit.prevent="saveSMS">
          <b-form-group id="input-group-id" label="ID" label-for="input-id">
            <b-form-input
              id="input-id"
              v-model="form.id"
              type="text"
              required
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-6" id="input-created_at" label="Skickas tidpunkt" label-for="input-1">
            <memlist-datetime-picker
              v-model="form.created_at"
              class="mb-8"
              placeholder="SMS-tidpunkt"
            />
          </b-form-group>

          <b-form-group
            class="col-6"
            id="to_number-group"
            label="Till nummer"
            label-for="to_number"
          >
            <b-form-input
              id="to_number"
              v-model="form.to_number"
              placeholder="Till nummer"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            class="col-6"
            id="from_id-group"
            label="Från ID"
            label-for="from_id"
          >
            <b-form-input
              id="from_id"
              v-model="form.from_id"
              placeholder="Från ID"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            class="col-6"
            id="cost_sek-group"
            label="Kostnad"
            label-for="cost_sek"
          >
            <b-form-input
              id="cost_sek"
              v-model="form.cost_sek"
              placeholder="Kostnad"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            class="col-6"
            id="sms_text-group"
            label="SMS Text"
            label-for="sms_text"
          >
            <b-form-textarea
              id="input-sms_text"
              size="sm"
              placeholder="SMS Text"
              v-model="form.sms_text"
            ></b-form-textarea>
          </b-form-group>

          <b-button type="submit" variant="primary">Spara</b-button>
        </b-form>


      </div>
    </b-card>

    <SMSTable
      :smss="smss"
      @createSMSClicked="createSMSClicked"
      @deleteSMSClicked="deleteSMSClicked"
      @selectSMSClicked="selectSMSClicked"
      @toastr="toastr"
    />
    <div class="scroll-to-container"></div>

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";
</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SMSTable from '@/view/pages/ml/sms/SMSTable.vue';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'smss',
  components: {
    SMSTable,
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
  },
  mounted() {

    this.from_date = dayjs().startOf('month').format('YYYY-MM-DD');
    this.to_date = dayjs().endOf('month').format('YYYY-MM-DD');

    this.loadData();
  },
  data() {
    return {
      from_date: '',
      to_date: '',
      from_id: null,
      smss: [],
      form: null
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadSMSs();
      }
    },
    from_date(newValue, oldValue) {
      this.loadSMSs();
    },
    to_date(newValue, oldValue) {
      this.loadSMSs();
    },
  },
  methods: {
    createSMSClicked() {
      this.scrollToEditSMS();

      this.form = {

      };
    },
    deleteSMSClicked(id) {
      axios
        .delete(`/sms/${id}`)
        .then(res => {

          if (res.status === 204) {
            this.smss = this.smss.filter(item => item.id !== id);
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Fel att radera SMS');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte radera');
        });
    },
    selectSMSClicked(id) {
      this.form = this.smss.find(item => item.sms_id === id);
    },
    async loadData() {
      this.loadSMSs();
    },
    async loadSMSs() {
      this.smss = [];

      axios
        .get(`/sms?from_date=${this.from_date}&to_date=${this.to_date}`)
        .then(res => {
          if (res.status !== 200) {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Fel att hämta SMS');
            return;
          }

          this.smss = res.data;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista SMS');
        });
    },
    scrollToEditSMS() {
      var that = this;
      setTimeout(function () {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },
    saveSMS() {
      if (!this.form.id) {
        axios
          .post(`/sms`, this.form)
          .then(res => {
            if (res.status !== 201) {
              this.toastr('danger', this.$t('COMMON.ERROR'), 'Fel att skapa SMS');
              return;
            }

            this.toastr('success', this.$t('COMMON.OK'), 'SMS har skapats');

            this.smss.push(res.data);

            this.form = res.data;
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa SMS');
          });
      }
      else {
        axios
          .put(`/sms/${this.form.id}`, this.form)
          .then(res => {
            if (res.status !== 204) {
              this.toastr('danger', this.$t('COMMON.ERROR'), 'Fel att uppdatera SMS');
              return;
            }
            this.toastr('success', this.$t('COMMON.OK'), 'SMS har uppdaterats');

            const t = this.smss.find(item => item.id === this.form.id);

            t.created_at = this.form.created_at;
            t.cost_sek = this.form.cost_sek;

          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera SMS');
          });
      }
    },

  }
};
</script>
